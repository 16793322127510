import React, { useState, useEffect} from 'react';
import { Box, Button, Typography, MobileStepper } from '@mui/material';
import { StepOptions, Steps } from '../../components/create_interview/Const';
import { Helmet } from 'react-helmet';
import { TopBar } from '../../components/top_bar/TopBar';
import RoundCard from '../../components/create_interview/RoundCard';
import JobDes from '../../components/create_interview/JobDes';
import './CreateInterview.css';
import '../../styles/Text.css'
import '../../styles/Button.css'
import '../../styles/DotStepper.css'

export default function CreateInterview({ setInterview }) {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [resume, setResume] = useState(null);
    const [jobDes, setJobDes] = useState("");
    const [resumeRequired, setResumeRequired] = useState(false);
    const TITLE = 'Interview - Mirwork';


    useEffect(() => {
        const initialOptions = {};
        initialOptions[0] = 'no_choice';
        initialOptions[1] = 'no_choice';
        for (let i = 2; i <= StepOptions.length; i++) {
            initialOptions[i] = [];
        }
        setSelectedOptions(initialOptions);
    }, []); 
  
    const handleCardClick = (value) => {
      setSelectedOptions({ ...selectedOptions, [activeStep]: value });
      if (value === 'hr-round') {
        setResumeRequired(true);
      } else {
        setResumeRequired(false);
      }
    };

  
    const handleNext = () => {
        if (activeStep < StepOptions.length - 1) {
            setActiveStep(prevStep => prevStep + 1);
        } else {
            setInterview(resume, jobDes, selectedOptions[0])
            return;
        }
    };
  
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(prevStep => prevStep - 1);
        }
    };
  
    const renderChoices = () => {
        const cardContainerClass = activeStep < 1 ? "newinterview-card-container-round" : "newinterview-job-description-container";
    
        return (
            <Box className={cardContainerClass}>
                {
                    activeStep < 1?
                        StepOptions[activeStep].choices.map((choice) => {
                        return (
                            <RoundCard
                                key={choice.value}
                                choice={choice}
                                isSelected={selectedOptions[activeStep] === choice.value}
                                onClick={() => handleCardClick(choice.value)}
                            />
                        );
                        }):
                        <JobDes 
                            jobDes={jobDes}
                            setJobDes={setJobDes}
                            setResume={setResume}
                            resumeRequired = {resumeRequired}
                        />
                }
            </Box>
        );
    };
    
    const CreateInterviewPage =  (
    <>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <TopBar/>
        <Box className="newinterview-survey">         
          <MobileStepper variant="dots" steps={2} position="static"  activeStep={activeStep} className='dot-stepper'/>
          <Typography className='title-text-thin' style={{marginBottom: '1%'}}> 
            {Steps[activeStep]}     
          </Typography>
          {activeStep > 0 && (
              <Typography component="span" className="small-grey-text" style={{marginBottom: '3%',width: '34%', textAlign: 'center'}}>
              {" Share your resume and job description. MirWork will create a custom interview just for you, ensuring your privacy is protected."}
              </Typography>
          )}
          {renderChoices()}
          <Box className="newinterview-button-container">
            {activeStep !== 0 && (
              <Button variant="contained" onClick={handleBack} className="main-button-white-middle">
                Back
              </Button>
            )}
            <Button variant="contained" onClick={handleNext} className="main-button-middle" 
             disabled={((activeStep === 0 && selectedOptions[activeStep] === 'no_choice'))||(activeStep === Steps.length - 1 && resumeRequired && !resume)}>
              {activeStep === Steps.length - 1 ? 'Finish' : 'Continue'}
            </Button>
          </Box>
        </Box>
    </>
    );

    return (
        CreateInterviewPage
    )
}

