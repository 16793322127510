import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Typography, MobileStepper, TextField } from '@mui/material';
import { StepOptions, Steps } from '../../components/user_survey/Const';
import { TopBar } from '../../components/top_bar/TopBar';
import SurveyCard from '../../components/user_survey/SurveyCard';
import SurveyCheckBox from '../../components/user_survey/SurveyCheckBox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { Helmet } from 'react-helmet';
import './UserSurvey.css';
import '../../styles/Text.css'
import '../../styles/Button.css'
import '../../styles/DotStepper.css'
import posthog from 'posthog-js';

export default function UserServey() {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
    const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
    const { isSignedIn, user, isLoaded } = useUser();
    const TITLE = 'User survey - Mirwork';;

    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirect');
    const navigate = useNavigate();

    useEffect(() => {
        posthog.capture('$pageview', {
            $current_url: window.location.href,
            $host: window.location.hostname,
            $pathname: window.location.pathname,
        });
        const initialOptions = {};
        initialOptions[0] = {};
        initialOptions[1] = 'no_choice_goal';
        initialOptions[2] = 'no_choice_timeline';
        for (let i = 3; i <= StepOptions.length; i++) {
            initialOptions[i] = [];
        }
        setSelectedOptions(initialOptions);
    }, []);

    const handleNameInput = (field, value) => {
        setUserInfo(prev => ({ ...prev, [field]: value }));
    };

    const handleCardClick = (value) => {
        setSelectedOptions({ ...selectedOptions, [activeStep]: value });
    };

    const handleCheckboxChange = (value) => {
        const allAboveStepIndex = 4;
        const currentSelections = selectedOptions[activeStep] || [];

        if (activeStep === allAboveStepIndex && value === 'all') {
            if (currentSelections.includes(value)) {
                setSelectedOptions({
                    ...selectedOptions,
                    [activeStep]: []
                });
            } else {
                const allValues = StepOptions[activeStep].choices.map(choice => choice.value);
                setSelectedOptions({
                    ...selectedOptions,
                    [activeStep]: allValues
                });
            }
        } else {
            const newSelections = currentSelections.includes(value)
                ? currentSelections.filter(v => v !== value)
                : [...currentSelections, value];

            if (activeStep === allAboveStepIndex) {
                const allIndex = newSelections.indexOf('all');
                if (allIndex > -1) {
                    newSelections.splice(allIndex, 1);
                }
            }

            setSelectedOptions({
                ...selectedOptions,
                [activeStep]: newSelections
            });
        }
    };

    const handleNext = async () => {
        if (activeStep === 0 && (!userInfo.firstName || !userInfo.lastName)) {
            alert("Please enter your first and last name.");
            return;
        }
        if (activeStep === Steps.length - 1) {
            await handleSubmit();
            navigate(redirectUrl);
        } else if (activeStep < StepOptions.length - 1) {
            setActiveStep(prevStep => prevStep + 1);
        }
    }
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(prevStep => prevStep - 1);
        }
    };

    const handleSubmit = async () => {
        if (!isSignedIn) {
            console.error("Error: User is not authenticated.");
            throw new Error("User is not authenticated.");
        }

        try {
            await axios.post(apiUrl, {
                firstname: userInfo.firstName,
                lastname: userInfo.lastName,
                email: user.primaryEmailAddress.emailAddress,
                goal: selectedOptions[1],
                timeline: selectedOptions[2],
                industries: selectedOptions[3],
                companies: selectedOptions[4],
                roles: selectedOptions[5],
                surveyFinished: true,
            })
                .then(response => {
                    console.log("Signup successful:", response.data);
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error during signup process:", error);
        }
    };


    const renderChoices = () => {
        const cardContainerClass = activeStep < 3 ? "survey-card-container" : "survey-checkbox-container";
        if (activeStep === 0) {
            return (
                <Box className="survey-text-container">
                    <TextField
                        className='survey-textfield'
                        label="First Name"
                        value={userInfo.firstName}
                        onChange={(e) => handleNameInput('firstName', e.target.value)}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        className='survey-textfield'
                        label="Last Name"
                        value={userInfo.lastName}
                        onChange={(e) => handleNameInput('lastName', e.target.value)}
                        margin="normal"
                        fullWidth
                    />
                </Box>
            );
        } else {
            return (
                <Box className={cardContainerClass}>
                    {StepOptions[activeStep].choices.map((choice) => {
                        if (activeStep < 3) {
                            return (
                                <SurveyCard
                                    key={choice.value}
                                    choice={choice}
                                    isSelected={selectedOptions[activeStep] === choice.value}
                                    onClick={() => handleCardClick(choice.value)}
                                />
                            );
                        } else {
                            return (
                                <SurveyCheckBox
                                    key={choice.value}
                                    choice={choice}
                                    isSelected={selectedOptions[activeStep] && selectedOptions[activeStep].includes(choice.value)}
                                    onChange={handleCheckboxChange}
                                />
                            );
                        }
                    })}
                </Box>
            );
        }

    };

    const SurveyPage = (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <TopBar />
            <Box className="user-servey">
                <MobileStepper variant="dots" steps={6} position="static" activeStep={activeStep} className='dot-stepper' />
                <Typography className='small-grey-text' style={{ marginBottom: '5px' }}> GETTING TO KNOW YOU </Typography>
                <Typography className='title-text-thin' style={{ marginBottom: '15px' }}> {Steps[activeStep]} </Typography>
                {renderChoices()}
                <Box className="survey-button-container">
                    {activeStep !== 0 && (
                        <Button variant="contained" onClick={handleBack} className="main-button-white-middle">
                            Back
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleNext} className="main-button-middle">
                        {activeStep === Steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                </Box>
            </Box>
        </>
    );

    return (
        SurveyPage
    )
}

