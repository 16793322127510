import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TopBar } from '../../components/top_bar/TopBar';
import { Radar } from '../../components/history/Radar';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from "react-router-dom";
import { FindUser } from '../../components/auth/FindUser';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import './History.css';
import '../../styles/Text.css';
import { getOverallScoreWithScores } from '../../functions/GetOverallScore';
import { getRadarKeys } from '../../functions/GetRadarKeys';
import posthog from 'posthog-js';

export default function History() {
  const [hoveredDate, setHoveredDate] = useState(null);
  const [hoveredScore, setHoveredScore] = useState(null);
  const [hoveredQuestion, setHoveredQuestion] = useState(null);
  const [hoveredOverview, setHoveredOverview] = useState("");
  const [radarDataNew, setRadarData] = useState([]);
  const [userData, setUserData] = useState(null); 
  const rootUrl = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = useState(true);
  const [evaluationCriteria, setEvaluationCriteria] = useState([]);
  const navigate = useNavigate();
  const { isSignedIn, user, isLoaded } = useUser()
  const TITLE = 'Interview History - Mirwork';

  useEffect(() => {
    const fetchUserData = async () => {  
      if (user!==undefined) {
        if(isSignedIn){
          posthog.identify(user.primaryEmailAddress.emailAddress);
          const userDataResult = await axios.post(rootUrl + "/api/userData/history", { email: user.primaryEmailAddress.emailAddress });
          setUserData(userDataResult.data.data);
          console.log("[INFO] history data: ", userDataResult.data.data);
          setLoading(false);          }
        else{
          navigate(`/home`);
        }
      }
    };
    fetchUserData();
  }, [isSignedIn]);

  useEffect(() => {
    if (!loading && userData && userData.dates && userData.dates.length > 0) {
      setRadarData(generateRadarData(0));
      setHoveredDate(null);
      setHoveredQuestion(userData.interviewQuestions[0]);
      setHoveredScore(
        getOverallScoreWithScores(userData.radarValues[0], getRadarKeys(userData.interviewRounds[0], userData.evaluationCriterias[0]))
      );
      setHoveredOverview(userData.overviews[0]);
    }
  }, [loading, userData]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    posthog.capture('$pageview', {
      $current_url: window.location.href,
      $host: window.location.hostname,
      $pathname: window.location.pathname,
    });
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const generateRadarData = (index) => {
    if (!userData || !userData.interviewRounds || !userData.radarValues) return [];
    const radarKeys = getRadarKeys(userData.interviewRounds[index], userData.evaluationCriterias[index])
    const radarData = radarKeys.map((key, i) => ({
      section: key.title,
      'score (0-5)': !isNaN(parseFloat(userData.radarValues[index][i])) && isFinite(userData.radarValues[index][i]) ? userData.radarValues[index][i] : 0
    }));
    setHoveredScore(
      getOverallScoreWithScores(userData.radarValues[index], radarKeys)
    );
    setHoveredOverview(userData.overviews[index])
    return radarData;
  };

  const handleMouseEnter = (index) => {
    if (userData) {
      setHoveredDate(userData.dates[index]);
      setHoveredScore(
        getOverallScoreWithScores(userData.radarValues[index], getRadarKeys(userData.interviewRounds[index], userData.evaluationCriterias[index]))
      );
      setHoveredQuestion(userData.interviewQuestions[index]);
      setRadarData(generateRadarData(index));
      setHoveredOverview(userData.overviews[index]);
    }
  };

  const handleMouseLeave = () => {
    if (userData) {
      setRadarData(generateRadarData(0));
      setHoveredDate(null);
      setHoveredQuestion(userData.interviewQuestions[0]);
      setHoveredScore(
        getOverallScoreWithScores(userData.radarValues[0], getRadarKeys(userData.interviewRounds[0], userData.evaluationCriterias[0]))
      );
      setHoveredOverview(userData.overviews[0])
    }
  };

  const handleRowClick = (path) => {
    navigate(path);
  };

  const handleButtonClick = (path) => {
    navigate(path);
  };

  if (loading) {
    return <div>Loading...</div>; // Add a loading state to display while fetching data
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TopBar />
      {(!userData || !userData.dates || userData.dates.length === 0) ? (
      <Box className='history-no-data-page'>
        <Box className='no-history-image-box'>
          <img src="../../../icons/empty-history.svg" />
        </Box>
        <Typography className="subtitle-text">
            Let's ace that interview today
        </Typography>
        <Box className="history-des-text">
          <Typography className="normal-text-light-small">
            Practive history and job recommendations will be available once you complete your first practice session.
          </Typography>
        </Box>
        <Button className='main-button-semimiddle-thin' onClick={() => handleButtonClick(`/interview`)}>New Practice</Button>
      </Box>
    ) : (
      <Box className="history-container">
        {/* Left box */}
        <Box className="history-left-box" style={{ display: windowWidth <= 900 ? 'none' : 'block' }}>
          <Typography className="subtitle-text history-subtitle-text-history">
            {hoveredDate ? `Interview on ${hoveredDate}` : 'Your latest interview'}
          </Typography>

          {/* Card component */}
          <Box className="history-left-card">
            <Box className="history-score-text-container">
              <Typography variant="body1" className="normal-text-light">
                Your score is
              </Typography>
              <Typography variant="body1" className="history-score-value">
                {hoveredScore && <>{hoveredScore}<span className="history-score-suffix">/5</span></>}
              </Typography>
              <Typography variant="body1" className="normal-text-light-small" style={{marginTop: '2%'}}>
                {hoveredQuestion && `Interview Question: ${hoveredQuestion}`}
              </Typography>
              <Typography variant="body1" className="normal-text-light-small">
                {hoveredOverview && `${hoveredOverview}`}
                {/* You can communicate and present your project very clearly. You also show that you are open to collaboration with other team members such as Engineer, Designer, or Business Analyst. */}
              </Typography>
            </Box>
            <Box className="history-radar-container">
              <Radar radarData={radarDataNew} />            
            </Box>
          </Box>
        </Box>
        {/* Right side box */}
        <Box className="history-right-box" style={{ width: windowWidth <= 900 ? '100%' : 'auto' }}>
          <Typography className="subtitle-text history-subtitle-text-history">
            All interviews
          </Typography>
          <Box className="history-interviews-table">
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell><Typography className="normal-text-light">Date</Typography></TableCell>
                  <TableCell><Typography className="normal-text-light">Position</Typography></TableCell>
                  <TableCell><Typography className="normal-text-light">Interview Round</Typography></TableCell>
                  <TableCell><Typography className="normal-text-light">Score</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.dates.map((date, index) => (
                  <TableRow
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleRowClick(`/feedback?id=${userData.feedbackId[index]}`)}
                    className="history-interview-row"
                  >
                    <TableCell><Typography variant="body1" className="normal-text-light">{date}</Typography></TableCell>
                    <TableCell><Typography variant="body1" className="normal-text-light">{userData.jobTitles[index]}</Typography></TableCell>
                    <TableCell><Typography variant="body1" className="normal-text-light">{userData.interviewRounds[index]}</Typography></TableCell>
                    <TableCell><Typography variant="body1" className="normal-text-light">
                      {getOverallScoreWithScores(userData.radarValues[index], getRadarKeys(userData.interviewRounds[index], userData.evaluationCriterias[index]))}
                    </Typography></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>)}
    </>
  );
}