import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faVideo, faVideoSlash, faMicrophoneSlash, faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { Box, Stack, Button, Tooltip } from '@mui/material';
import "./Footer.css";
import { getChatHist } from '../chat_history/speech-to-text.jsx';
import posthog from "posthog-js";


const Footer = (props) => {
  const [mic, setMic] = useState(props.isMicOn);
  const [video, setVideo] = useState(props.isVideoOn);
  const [cc, setCc] = useState(false);
  const [candidateEmail, setCandidateEmail] = useState(props.candidateEmail);
  const [jobId, setJobId] = useState(null);
  const [quickMockJobId, setQuickMockJobId] = useState(null);
  const joinTime = props.joinTime;

  useEffect(() => {
      // 2b
      const queryParams = window.location.search.slice(1); 
      const paramsArray = queryParams.split('&'); 
  
      const jobParam = paramsArray.find(param => param.startsWith('job='));

      if (jobParam) { 
        const jobId = jobParam.split('=')[1]; 
        setJobId(jobId);
      } else if (props.quickMockJobId) {
        setQuickMockJobId(props.quickMockJobId);
      }
  }, []);


  const micClick = () => {
    props.onMicClick(!mic);
    setMic(!mic);
  };

  const videoClick = () => {
    props.onVideoClick(!video);
    setVideo(!video);
  };

  const ccClick = () => {
    props.onccClick(!cc);
    setCc(!cc);
  };

  const handleEndButtonClick = () => {
    props.setEndButtonClicked(true);
    posthog.capture('interview_click_end_button', {});

    if (joinTime) {
      const durationMs = Date.now() - joinTime;
      const durationMinutes = durationMs / 1000 / 60;
      posthog.capture('interview_duration', {duration_minite: durationMinutes});
    }

    const fullChatHist = JSON.stringify(getChatHist(), null, 2).replace(/\\n/g, '\n');
    const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/openai/postInterview";
    axios.post(apiUrl, {
      userEmail: candidateEmail,
      message: fullChatHist,
      promptId: props.interviewPromptId,
      interviewQuestion: props.interviewQuestion,
      jobId: jobId,
      quickMockJobId: quickMockJobId,
      round: props.round,
    })
      .then(response => {
        const queryParamsObj = new URLSearchParams({ id: response.data.data }); // id: chatID
        if (jobId){
          queryParamsObj.append('job', jobId);
        }
        const queryParams = queryParamsObj.toString();
        window.location.href = `/feedback?${queryParams}`;
      })
      .catch(error => {
        console.error("Error saving message:", error);
      });
  };

  
  return (
    <>
    {
      props.hasEndButtonClicked? <></> :
    
        <Box className="footer">
          <Stack direction="horizontal">
            <Button
              variant="contained"
              size="large"
              className={!mic ? "footer-icons-active" : "footer-icons"}
              onClick={micClick}
            >
              <Tooltip title={mic ? "Mute" : "Unmute"}>
                <FontAwesomeIcon icon={!mic ? faMicrophoneSlash : faMicrophone} />
              </Tooltip>
            </Button>

            <Button
              variant="contained"
              size="large"
              className={"footer-icons" + (!video ? "-active" : "")}
              onClick={videoClick}
            >
              <Tooltip title={video ? "Stop Video" : "Start Video"}>
                <FontAwesomeIcon icon={!video ? faVideoSlash : faVideo} />
              </Tooltip>
            </Button>

            {
              props.hasUserJoined &&

              <Button
                variant="contained"
                size="large"
                className={"footer-icons" + (cc ? "" : "-inactive")}
                onClick={ccClick}
              >
                <Tooltip title={cc ? "Hide History" : "Show History"}>
                  <FontAwesomeIcon icon={faClosedCaptioning} />
                </Tooltip>
              </Button>
            }
          </Stack>

          {
            props.hasUserJoined &&
            <Button className="normal-text end-button" onClick={handleEndButtonClick}>End</Button>
          }

        </Box>
    }
    </>
  );
};

export default Footer;